<!--
 * @Author: your name
 * @Date: 2020-12-17 15:32:34
 * @LastEditTime: 2021-01-25 22:33:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\serviceprotocol\index.vue
-->
<template>
  <div class="allmsg">
    <navigation :msg="msg" />
    <pre>
<h1>萝卜猎手服务协议</h1>

<h2>甲方：</h2>
<h2>乙方：上海猎后征信服务有限公司</h2>
    感谢您选择 上海猎后征信服务有限公司 为您提供基于大数据背景调查的企业用工综合服务，我司将依托综合实力竭诚为您提供最规范、专业的服务。
    为规范双方合作，确保双方利益，按《中华人民共和国合同法》的相关规定，双方经协商一致并在上海市杨浦区拟定本协议，以共同遵守执行 。
<h3>一、服务内容及各方权利义务</h3>
    1、乙方(包括但不限于"萝卜猎手”官网、H5及APP) 是基于大数据背调的企业用工多元化服务平台，可为甲方提供员工背景调查、简历下载、人员招聘、员工租房等多项服务；
    2、甲方应当在本合同签订后，以甲方名义在“萝卜猎手“网站进行企业用户注册和认证，任何以甲方现有账号和密码登录＂萝卜猎手”网站进行操作、查询的入员，均视为获得了甲方的授权和许可。
    3、甲方须获得被调查员工的授权。甲方确认并承诺，甲方向乙方提供被调查人员的基本信息时，甲方已经获得了被调查人员合法、有效的授权。
    4、甲方承诺，甲方获得被调查人员的调查信息后，仅用于以聘用目的在合理限度内使用调查信息，如甲方非法使用调查信息，造成他人损失或者赔偿要求的，由甲方自行承担责任。
    5、乙方所出具的报告均来源于网络合法公开信息，乙方对信息来源的可靠性和真实性承担责任，但乙方因受制于无法判断该等网络公开信息的真伪，故对所提供的被调查机构和相关人员信息的真实性、准确性不承担任何责任。
    6、任何一方不得将调查信息用作任何除本合同以外的其他商业目的，否则，应承担合同金额20%的违约责任，同时赔偿守约方损失，如损失无法确认，以违约方获利为准。
    7、萝卜猎手网站系统所提供的各项服务所有权及经营权归乙方。
    8、合作期间，甲方默认成为乙方“合作伙伴”，乙方可在网站及官方宣传材料体现双方合作关系。
    9、双方在履行本合同过程中，均应严格遵守《合同法》的相关规定。

<h3>二、费用及付款方式</h3>
    1、甲方应根据对应订单价格，通过平台支付或银行转账等形式，将订单款项支付给乙方。双方同意，甲方支付至乙方的充值款项，仅用于冲抵乙方为甲方提供的相应服务的价款，充值款项不可退还。
    2、乙方根据甲方要求于收款后提供发票，乙方可以通过平台填写开票信息与寄送地址。
    3、若因不可抗拒因素，导致乙方不能继续为甲方提供服务的，双方可协商解决退款事宜。

<h3>三、合同期限</h3>
    1、本协议有效期为自签订之日起2个自然年 ，
    2、 如未遇特殊情况或乙方未正式提出书面通知，线上部分服务帐户金充值进入后不计有效期限。如遇特殊情况，乙方应书面提出通知，经双方协商并重新签订《合同》或《补充协议》，方可生效。

<h3>四、合法性及保密条款</h3>
    1、甲方应在委托前明确告知应聘候选人由乙方协助进行背景调查工作，并确保应聘候选人配合完成授权及完整信息等资料，甲方违反本保证义务所引发的责任由甲方承担，由此给乙方造成法律风险的，甲方应承担相关法律、赔偿责任。
    2、乙方保证其所提交的报告中涉及的客观信息均为经过尽责核实的，并保证真实记录调查结果。若甲方发现乙方所核实并记录在报告中的客观信息有误，并可出示法律认可的证据证明此类信息有误，乙方及时配合整改。
    3、乙方将对其访问核实所获得的全部信息以及服务过程中了解到、看到、接收到的甲方公司信息、应聘候选人信息等信息进行保密，保密期限不受本合同有效期限制，未经甲方书面许可，不得对任何第三方透露。
    4、甲方将对其在接受乙方服务过程中了解到、看到、接收到的乙方公司信息、产品信息（包括与此相关的信息技术、商务资料、系统内容等任何文件及信息）、合同、战略规划等信息进行保密，保密期限不受本合同有效期限制，未经乙方书面许可，不得对任何第三方透露。
    5、若甲方未经乙方书面同意，向任何第三方泄露、转让、出售本产品信息而造成的任何纠纷，乙方不承担任何责任，并且有权立即中止甲方继续使用相关信息及服务的资格。甲方如因此类违约行为给乙方造成实际损失的，应承担相应赔偿责任。
    6、乙方不对甲方是否雇佣候选人提出任何建议。乙方在提交客观报告内容的前提下，不对甲方是否雇佣候选人而造成的结果承担任何责任。乙方将不对报告中未提及的信息承担任何法律责任。

<h3>五、争议解决</h3>
    本协议的订立、执行和解释及争议的解决均应适用中国（为本协议方便表述之目的，中国不含香港、澳门、台湾地区）法律。凡因本协议引起的或与本协议有关的任何争议，双方应友好协商解决。如不能协商解决，双方同意向上海仲裁委员会提起仲裁。

    </pre>
  </div>
</template>
<script>
export default {
  components: {
   
  },
  data() {
    return {
      msg: "服务协议",
    };
  },
  mounted(){
    
  }
};
</script>
<style scoped>
.allmsg {
  background-color: #ffffff;
  overflow: hidden;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  padding: 0.2rem;
}
</style>